<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Forgot password</h1>
                  <p class="text-muted">Enter your email address</p>
                  <CInput
                    placeholder="user@example.com"
                    autocomplete="email"
                    v-model="email"
                    type="email"
                    required
                    was-validated
                  >
                  </CInput>

                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton
                        color="primary"
                        class="px-4"
                        v-on:click="forgotPassword(email)"
                        >Continue</CButton
                      >
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import AuthProxy from '@/proxies/auth.proxy';
import { ValidateEmail } from '@/utils/utils';

export default {
  name: 'ForgotPassword',
  data() {
    return {
      loading: false,
      email: '',
    };
  },
  methods: {
    forgotPassword(email) {
      if (ValidateEmail(email)) {
        AuthProxy.forgotPassword(email)
          .then(() => {
            this.$toast.success('Check your email', { duration: 5000 });
            this.$router.push({ name: 'login.index' });
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
  },
};
</script>
